@import "../../styles/variables.scss";

.editorContainer {
    position     : relative;
    width        : 100%;
    margin-bottom: 50px;

    textarea {
        resize   : none;
        width    : 100%;
        height   : 100px;
        max-width: 800px;
        margin   : 0 auto;
        display  : block;
        padding  : 0 10px;
    }

    button {
        position        : relative;
        display         : block;
        margin          : 10px auto;
        padding         : 20px;
        border          : 1px solid #000;
        cursor          : pointer;
        background-color: #fff;
        margin-top      : 50px;
        font-family     : 'Spectral SC', serif;

        &:hover {
            background-color: #eee;
        }

        @media (max-width: $responsive-small) {
            margin-top: 10px;
        }
    }
}

.leftEditor {
    position       : relative;
    float          : left;
    height         : 650px;
    width          : 100%;
    display        : flex;
    align-items    : center;
    flex-direction : column;
    justify-content: center;
    border-right   : 1px dotted #000;
}

.editorTitle {
    position     : relative;
    font-family  : 'Spectral SC', serif;
    width        : 100%;
    text-align   : center;
    margin-bottom: 20px;
}

.rightEditor {
    position: relative;
    float   : left;
    width   : 100%;
}

.partEditor {
    position: relative;
    width   : 100%;
}

.imageEditor {
    display        : flex;
    justify-content: center;

    .imagesContainer {
        position        : relative;
        width           : 400px;
        max-width       : 100%;
        background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M8 0h8v8H8zM0 8h8v8H0z"/></svg>');

        img {
            width: 100%;
        }

        @media (max-width: $responsive-small) {
            padding: 0 !important;
        }
    }

    .shadows {
        position: relative;
    }

    .fill,
    .body,
    .numbers {
        position  : absolute;
        left      : 0;
        top       : 0;
        transition: all 0.2s;
    }
}

.numberContainer,
.bodyContainer,
.innerContainer {
    position: relative;
    float   : left;
    width   : 33.3%;
    height  : 250px
}

.bodyContainer {
    border-left : 1px dotted #000;
    border-right: 1px dotted #000;

    @media (max-width: $responsive-small) {
        border-top   : 1px dotted #000;
        border-bottom: 1px dotted #000;
        border-left  : none;
        border-right : none;
    }
}

.numColor {
    position: relative;
    padding : 5px;
    width   : 35px;
    height  : 35px;
    display : inline-block;
    border  : 1px solid #000;
    margin  : 10px;
    cursor  : pointer;

    &:hover {
        padding: 2px;
    }

    &>div {
        position           : relative;
        width              : 100%;
        height             : 100%;
        display            : block;
        background-repeat  : no-repeat;
        background-position: center;
        background-size    : 250%;
    }
}

.react-colorful {
    margin: 0 auto;
}


.pImageEditor {
    position  : relative;
    text-align: center;

    img {
        width : 200px;
        margin: 20px;

        @media (max-width: $responsive-small) {
            padding: 5px;
            width  : 100px;
        }
    }
}


.editorPart>div {
    @media (max-width: $responsive-small) {
        width         : 100%;
        padding-top   : 20px;
        padding-bottom: 20px;
        height        : auto;
    }
}