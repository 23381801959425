@import "../../styles/variables.scss";

nav {
    position: relative;
    display : inline-block;

    a {
        margin         : 0 10px;
        text-transform : capitalize;
        text-decoration: none;
        color          : #000;
        font-size      : 21px;
        font-family    : 'Spectral SC', serif;
        position       : relative;

        &:after {
            content         : " ";
            position        : absolute;
            bottom          : 0;
            left            : -5px;
            width           : 0;
            height          : 1px;
            background-color: #000;
            transition      : all 0.2s;
        }

        &::before {
            bottom: auto;
            top   : 0;
        }

        &:hover:after,
        &.selected::after {
            width: calc(100% + 10px);
        }


        @media (max-width: $responsive-small) {
            margin      : 10px;
            // width    : calc(50% - 20px);
            display     : block;
            font-size   : 18px;
        }
    }

    @media (max-width: $responsive-small) {
        margin: 20px 0;
    }
}