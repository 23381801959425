@import "../../styles/variables.scss";

.icon {
    position       : relative;
    display        : inline-block;
    width          : 20px;
    height         : 20px;
    cursor         : pointer;
    text-align     : center;
    margin         : 0 10px;
    text-decoration: none;

    img {
        display        : block;
        width          : 100%;
        height         : 100%;
        object-position: center;
        object-fit     : scale-down;
    }

    @media (max-width: $responsive-small) {
        display: block;
        float  : left;
        margin : 10px 0;
        width  : 40px;
        height : 40px;
    }
}

a.icon+div.icon {
    @media (max-width: $responsive-small) {
        clear: left;
    }
}