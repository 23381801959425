@import "../../styles/variables.scss";

label {
    display    : block;
    width      : 100%;
    margin     : 10px 0;
    font-size  : 21px;
    font-weight: lighter;

    &.required::after {
        content: "*";
    }
}

form {
    input {
        padding  : 10px;
        font-size: 16px;
        width    : 50%;

        @media (max-width: $responsive-small) {
            width: 100%;
        }
    }

    p {
        margin-top: 50px;
        text-align: right;
    }

    textarea {
        width : 100%;
        resize: none;
        height: 100px;
    }

    input[type=submit] {
        position        : relative;
        width           : auto;
        display         : block;
        margin          : 10px auto;
        padding         : 20px;
        border          : 1px solid #000;
        cursor          : pointer;
        background-color: #fff;
        margin-top      : 50px;
        font-family     : 'Spectral SC',
            serif;
        font-size: 14px;
    }

    input[type=submit]:hover {
        background-color: #eee;
    }
}