@import "./styles/variables.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family            : 'Spectral', serif;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.clearBoth {
  clear  : both;
  padding: 0 !important;
  margin : 0 !important;
}

.content-wrapper {
  position  : relative;
  width     : 80%;
  margin    : auto;
  box-sizing: border-box;

  @media (max-width: $responsive-small) {
    width: 90%;
  }
}

h1 {
  font-size  : 30px;
  font-weight: lighter;
  margin-top : 0;
  text-align : right;
}

h2 {
  font-size  : 30px;
  font-weight: lighter;
  margin-top : 80px;
}

h2:first-child {
  margin-top: 0;
}

ol,
p {
  font-size  : 21px;
  font-weight: lighter;
  text-align : justify;
  margin     : 15px 0;
}

li {
  margin-bottom: 10px;
}

p.centered {
  text-align: center;
  margin    : 40px 0 !important;
}

p.red {
  color: darkred;
}

p:last-child {
  margin-bottom: 100px;
}

@media (max-width: $responsive-small) {

  h1,
  h2 {
    text-align: left;
    font-size : 26px;
  }

  p,
  ol {
    font-size: 19px;
  }
}