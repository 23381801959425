@import "../../styles/variables.scss";

.socialArea {
    position   : relative;
    margin-left: auto;
    text-align : right;

    @media (max-width: $responsive-small) {
        margin: auto;
        width : fit-content;
    }
}