.spinnerContainer {
    position   : absolute;
    width      : 100%;
    height     : 100%;
    background : rgba(255, 255, 255, 0.6);
    top        : 0;
    left       : 0;
    display    : flex;
    align-items: center;
}

.spinner {
    margin    : auto;
    width     : 70px;
    text-align: center;

    &>div {
        width            : 18px;
        height           : 18px;
        background-color : #333;
        border-radius    : 100%;
        display          : inline-block;
        -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
        animation        : sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
        -webkit-animation-delay: -0.32s;
        animation-delay        : -0.32s;
    }

    .bounce2 {
        -webkit-animation-delay: -0.16s;
        animation-delay        : -0.16s;
    }
}

@-webkit-keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0)
    }

    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bouncedelay {

    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform        : scale(0);
    }

    40% {
        -webkit-transform: scale(1.0);
        transform        : scale(1.0);
    }
}