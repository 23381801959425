@import "../../../styles/variables.scss";

header {
    position   : relative;
    display    : flex;
    align-items: center;
    height     : 180px;
    box-sizing : border-box;
    padding    : 50px;

    @media (max-width: $responsive-small) {
        flex-direction: column;
        height        : auto;
        padding       : 10px 20px;
    }

    .closeContainer {
        display   : flex;
        width     : 100%;
        overflow  : hidden;
        transition: all $menu-animation-time;

        .menuButton {
            position: absolute;
            display : block;
            left    : 20px;
            top     : 20px;
            width   : 30px;
            height  : 30px;
            display : none;

            .hambline {
                position  : absolute;
                left      : 0;
                width     : 100%;
                height    : 4px;
                background: black;
                top       : 0;
                transition: all $menu-animation-time;

                &.line2 {
                    top: 10px;
                }

                &.line3 {
                    top: 20px;
                }
            }


            @media (max-width: $responsive-small) {
                display: block;
            }
        }

        @media (max-width: $responsive-small) {
            display   : block;
            text-align: center;
            background: #eee;
            margin    : 20px 0;

            &.opened_false {
                max-height: 0;
            }

            &.opened_true {
                max-height: 400px;

                .menuButton {
                    .hambline {
                        &.line1 {
                            transform: rotate(45deg);
                            top      : 10px;
                        }

                        &.line2 {
                            opacity: 0;
                        }

                        &.line3 {
                            transform: rotate(-45deg);
                            top      : 10px;
                        }
                    }
                }
            }
        }
    }
}