@import "../../../styles/variables.scss";

footer {
    position        : relative;
    display         : flex;
    align-items     : center;
    height          : 180px;
    background-color: #eee;
    box-sizing      : border-box;
    padding         : 50px;
    justify-content : center;

    @media (max-width: $responsive-small) {
        height    : auto;
        text-align: center;
    }
}