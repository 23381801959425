@import "../../../styles/variables.scss";

.logo {
    position    : relative;
    display     : inline-block;
    margin-right: 60px;
    width       : 100px;
    text-align  : center;

    div {
        position       : relative;
        display        : flex;
        align-items    : center;
        justify-content: center;
        width          : 70px;
        height         : 70px;
        border-radius  : 50%;
        margin         : auto;

        img {
            display: block;
            height : 90%;
        }

        @media (max-width: $responsive-small) {
            height: 100px;
            width : 100px;
        }
    }

    span {
        display    : block;
        font-size  : 20px;
        margin-top : 5px;
        font-family: 'Questrial', sans-serif;

        /* font-family: 'Quicksand', sans-serif; */
        /* font-family: 'Comfortaa', cursive; */
        /* font-family: 'Poppins', sans-serif; */
        @media (max-width: $responsive-small) {
            font-size: 30px;
        }
    }

    @media (max-width: $responsive-small) {
        width : 100%;
        margin: 0;
    }
}