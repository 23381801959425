@import "../../styles/variables.scss";

.wrapper-part {
  position  : relative;
  float     : left;
  box-sizing: border-box;
  padding   : 50px;

  img {
    max-width    : 100%;
    background   : #eee;
    margin-bottom: 10px;
  }

  &.wrapper-half {
    width: 50%;

    @media (max-width: $responsive-small) {
      width: 100%;
    }
  }

  &.wrapper-third {
    width  : 33%;
    padding: 10px;

    @media (max-width: $responsive-small) {
      width: 100%;
    }
  }

  @media (max-width: $responsive-small) {
    padding: 0 !important;
  }
}